/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'></div>
      </div>
      <AsideMenuItemWithSub to={''} title='Hesaplama' defaultOpen={true}>
        <AsideMenuItem to='/calculate' title='Hesap İşlemleri' hasBullet={true} />
        <AsideMenuItemWithSub to='' title='Genel Parametreler' defaultOpen={true}>
          <AsideMenuItem to='/genel-parametreler/genel' title='Genel' hasBullet={true} />
          <AsideMenuItem to='/genel-parametreler/panel' title='Paneller' hasBullet={true} />
          <AsideMenuItem to='/genel-parametreler/profil' title='Profiller' hasBullet={true} />
          <AsideMenuItem to='/genel-parametreler/bayrak' title='Bayraklar' hasBullet={true} />
          <AsideMenuItem to='/genel-parametreler/motor' title='Motorlar' hasBullet={true} />
          <AsideMenuItem to='/genel-parametreler/tapa' title='Tapalar' hasBullet={true} />
          <AsideMenuItem
            to='/genel-parametreler/kontrol-unite-elemanlari'
            title='Kont. Uni. Elem.'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/genel-parametreler/tambur'
            title='Tambur Aralıkları'
            hasBullet={true}
          />
          <AsideMenuItem to='/genel-parametreler/boya' title='Boyalar' hasBullet={true} />
          <AsideMenuItem to='/genel-parametreler/oluk' title='Oluklar' hasBullet={true} />
          <AsideMenuItem
            to='/genel-parametreler/kilit-kol-mentese'
            title='Kilit/Kol/Menteşe'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
    </>
  )
}
