import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {deleteModel, getModel, updateParams} from '../CRUD'
import {showToast} from '../../../helpers/toast'

export const Genel = () => {
  const {model} = useParams<any>()
  const [data, setData] = React.useState<any>(null)
  const [formData, setFormData] = React.useState<any>({})
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await getModel(model)
        const result = response?.data?.data
        setData(result)

        const params: any = {}
        Object.entries(result.params).forEach(([key, value]) => {
          params[key] = value
        })
        result.elements.forEach((element: any) => {
          params[element.Adi] = element.EkoFiyat
        })
        setFormData(params)
      }
      fetchData()
    } catch (error) {
      setData([])
      console.log(error)
    }
  }, [model])

  const handleSave = async () => {
    try {
      const response = await updateParams(formData)
      if (response.data) {
        showToast.success('İşlem başarıyla tamamlandı')
      } else {
        showToast.error('İşlem başarısız oldu')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='card'>
      <div className='card-body p-2 p-lg-5'>
        <div className='d-block d-lg-none'>
          {data &&
            Object.entries(data?.params).map(([key, value], index) => (
              <div key={index} className='mb-3'>
                <label className='form-label fw-bold d-block'>{key}</label>
                <input
                  onChange={(e) => setFormData({...formData, [key]: e.target.value})}
                  value={formData[key] || value}
                  type='number'
                  step={0.01}
                  className='form-control form-control-sm'
                  defaultValue={Number(value)}
                />
              </div>
            ))}
          {data &&
            data?.elements.map((element: any, index: number) => (
              <div key={index} className='mb-3'>
                <label className='form-label fw-bold d-block'>{element.Adi}</label>
                <input
                  onChange={(e) => setFormData({...formData, [element.Adi]: e.target.value})}
                  value={formData[element.Adi] || element.EkoFiyat}
                  step={0.01}
                  type='number'
                  className='form-control form-control-sm'
                  defaultValue={element.EkoFiyat}
                />
              </div>
            ))}
        </div>

        <div className='d-none d-lg-block'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-5'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='min-w-150px'>Parametre</th>
                  <th className='min-w-150px'>Değer</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  Object.entries(data?.params).map(([key, value], index) => (
                    <tr key={index}>
                      <td className='fw-bold'>{key}</td>
                      <td>
                        <input
                          onChange={(e) => setFormData({...formData, [key]: e.target.value})}
                          value={formData[key] || value}
                          type='number'
                          step={0.01}
                          className='form-control form-control-sm'
                          defaultValue={Number(value)}
                        />
                      </td>
                    </tr>
                  ))}
                {data &&
                  data?.elements.map((element: any, index: number) => (
                    <tr key={index}>
                      <td className='fw-bold'>{element.Adi}</td>
                      <td>
                        <input
                          onChange={(e) =>
                            setFormData({...formData, [element.Adi]: e.target.value})
                          }
                          value={formData[element.Adi] || element.EkoFiyat}
                          step={0.01}
                          type='number'
                          className='form-control form-control-sm'
                          defaultValue={element.EkoFiyat}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='d-flex justify-content-end mt-3 mt-lg-5'>
          <button onClick={handleSave} className='btn btn-primary btn-sm w-100 w-lg-auto px-4'>
            Kaydet
          </button>
        </div>
      </div>
    </div>
  )
}
