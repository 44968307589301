import React from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {Bayrak} from '../modules/genel-parametreler/Bayrak/Bayrak'
import {Panel} from '../modules/genel-parametreler/Panel/Panel'
import {Profil} from '../modules/genel-parametreler/Profil/Profil'
import {Motor} from '../modules/genel-parametreler/Motor/Motor'
import {Tapa} from '../modules/genel-parametreler/Tapa/Tapa'
import {TamburAraliklari} from '../modules/genel-parametreler/TamburAraliklari/TamburAraliklari'
import {Boya} from '../modules/genel-parametreler/Boya/Boya'
import {Oluk} from '../modules/genel-parametreler/Oluk/Oluk'
import {Genel} from '../modules/genel-parametreler/Genel/Genel'
interface GeneralParamsProps {
  title: string
}
export const Parameters = ({title}: GeneralParamsProps) => {
  return (
    <>
      <div className={`card mb-5`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span
              className='card-label fw-bolder fs-3 mb-1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              {title}
            </span>
          </h3>
          {title != 'genel' && (
            <a
              href={`/genel-parametreler/${title}/new`}
              className='btn btn-lg btn-color-primary btn-active-light-primary'
            >
              Yeni Ekle
            </a>
          )}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {title === 'bayrak' && <Bayrak />}
          {title === 'panel' && <Panel />}
          {title === 'profil' && <Profil />}
          {title === 'motor' && <Motor />}
          {title === 'tapa' && <Tapa />}
          {title === 'tambur' && <TamburAraliklari />}
          {title === 'boya' && <Boya />}
          {title === 'oluk' && <Oluk />}
          {title === 'genel' && <Genel />}
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}
