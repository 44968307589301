import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {deleteModel, getModel} from '../CRUD'
import {showToast} from '../../../helpers/toast'
export const Panel = () => {
  const {model} = useParams<any>()
  const [data, setData] = React.useState([])
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await getModel(model)
        const result = response?.data?.data
        setData(result)
      }
      fetchData()
    } catch (error) {
      setData([])
      console.log(error)
    }
  }, [model])
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteModel({model, id})
      if (response.data) {
        showToast.success('İşlem başarıyla tamamlandı')
      } else {
        showToast.error('İşlem başarısız oldu')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='card'>
      <div className='card-body p-2 p-lg-5'>
        {/* Mobil Görünüm */}
        <div className='d-block d-lg-none'>
          {data &&
            data
              ?.sort((a: any, b: any) => b.Adi.localeCompare(a.Adi))
              ?.map((item: any, index: number) => (
                <div key={index} className='border rounded p-3 mb-3'>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <span className='fw-bold'>Panel Adı:</span> {item.Adi}
                    </div>
                    <div>
                      <span className='fw-bold'>Yükseklik:</span> {item.Yukseklik}
                    </div>
                    <div>
                      <span className='fw-bold'>Kalınlık:</span> {item.Kalinlik}
                    </div>
                    <div className='d-flex gap-2 mt-2'>
                      <a
                        href={`/genel-parametreler/panel/${item.id}`}
                        className='btn btn-sm btn-light-primary w-100'
                      >
                        Düzenle
                      </a>
                      <button
                        type='button'
                        onClick={() => handleDelete(item.id)}
                        className='btn btn-sm btn-light-danger w-100'
                      >
                        Sil
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        {/* Masaüstü Görünüm */}
        <div className='d-none d-lg-block'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                {data && data.length > 0 && (
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-150px'>Panel Adı</th>
                    <th className='min-w-100px'>Yükseklik</th>
                    <th className='min-w-100px'>Kalınlık</th>
                    <th className='min-w-150px text-end'>İşlemler</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {data &&
                  data
                    ?.sort((a: any, b: any) => b.Adi.localeCompare(a.Adi))
                    ?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className='fw-bold'>{item.Adi}</td>
                        <td>{item.Yukseklik}</td>
                        <td>{item.Kalinlik}</td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end gap-2'>
                            <a
                              href={`/genel-parametreler/panel/${item.id}`}
                              className='btn btn-sm btn-light-primary'
                            >
                              Düzenle
                            </a>
                            <button
                              type='button'
                              onClick={() => handleDelete(item.id)}
                              className='btn btn-sm btn-light-danger'
                            >
                              Sil
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
