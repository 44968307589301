import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {deleteModel, getModel} from '../CRUD'
import {showToast} from '../../../helpers/toast'
export const Oluk = () => {
  const {model} = useParams<any>()
  const [data, setData] = React.useState([])
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await getModel(model)
        const result = response?.data?.data
        setData(result)
      }
      fetchData()
    } catch (error) {
      setData([])
      console.log(error)
    }
  }, [model])
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteModel({model, id})
      if (response.data) {
        showToast.success('İşlem başarıyla tamamlandı')
      } else {
        showToast.error('İşlem başarısız oldu')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='table-responsive table-bordered'>
      {/* begin::Table */}
      <table className='table align-middle gs-0 gy-5'>
        {/* begin::Table head */}
        <thead>
          {data && data.length > 0 && (
            <tr className={'text-dark fw-bolder'}>
              <th>Oluk Adı</th>
              <th>Metre Fiyatı</th>
              <th className='p-0 w-40px'></th>
            </tr>
          )}
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data &&
            data.map((item: any, index: number) => (
              <tr key={index} className='text-dark fw-bolder'>
                <td>{item.Adi}</td>
                <td>{item.param1}</td>
                <td className='text-end'>
                  <div className='d-flex gap-2'>
                    <a
                      href={`/genel-parametreler/oluk/${item.id}`}
                      className='btn btn-sm btn-bg-light btn-active-color-primary'
                    >
                      Düzenle
                    </a>
                    <button
                      type='button'
                      onClick={() => handleDelete(item.id)}
                      className='btn btn-sm btn-danger'
                    >
                      Sil
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}
